import React, { Component } from "react";
import "./Cookies.css";

class Cookies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptCookies: false,
      firstConnected: true,
    };

    this.handleCookies = this.handleCookies.bind(this);
  }
  componentWillMount() {}
  componentDidMount() {}

  handleCookies(event) {
    this.setState({ firstConnected: true });
  }

  handleAccept = (event) => {
    this.setState({ firstConnected: false, acceptCookies: true });

    setTimeout(() => {
      localStorage.setItem("FirstConnected", false);
      localStorage.setItem("AcceptCookies", true);
    }, 100);
  };

  handleRefuser = (event) => {
    this.setState({ firstConnected: false, acceptCookies: false });

    setTimeout(() => {
      localStorage.setItem("FirstConnected", false);
      localStorage.setItem("AcceptCookies", false);
    }, 100);
  };
  render() {
    var fConnected = localStorage.getItem("FirstConnected");
    var templateCookies = "";

    if (fConnected == null && this.state.firstConnected == true) {
      templateCookies = (
        <div className="containerCookies">
          <div className="titreCookies"> Cookies</div>
          <div className="textCookies">
            Nous utilisons des cookies sur notre site Internet pour son bon
            fonctionnement et à des fins de mesure d'audience dans le but de
            vous offrir une expérience de visite améliorée et personnalisée. En
            cliquant sur « TOUT ACCEPTER », vous consentez à l'utilisation de
            tous les cookies placés sur notre site. En cliquant sur « REFUSER »,
            seuls les cookies strictement nécessaires au fonctionnement du site
            et à sa sécurité seront utilisés. Pour en savoir plus sur les
            cookies et les données personnelles que nous utilisons : lire notre
            politique de confidentialité
          </div>
          <div className="containerbtnCookies">
            <div className="btnAccepter" onClick={this.handleAccept}>
              TOUT ACCEPTER
            </div>
            <div className="btnRefuser" onClick={this.handleRefuser}>
              REFUSER
            </div>
          </div>
        </div>
      );
    } else {
      templateCookies = "";
    }

    return <div> {templateCookies} </div>;
  }
}

export default Cookies;
