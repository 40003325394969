// library
import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";

// style
import "./Register.css";
import "react-select-search/style.css";

// config
import { URL, ENV } from "../../Config/config.js";

//constants
import arryDep from '../../constants/departements.js';
import arryStatuts from "../../constants/statuts.js";
import arryCountries from "../../constants/pays.js";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prenom: "",
      nom: "",
      emailP: "",
      emailS: "",
      num_telephone: "",
      specialite: "",
      centre: "",
      rpps: "",
      password: "",
      rgpd: false,
      sended: false,
      valid: false,
      tmpAlerte: 0,
      tmpAlerteEmail: 0,
      centers: [],
      specialites: [],
      etatCasscade: 0,
      departements: [],
      departement: "",
      countries: [],
      pays: "",
      Precision: "",
      Laboratoire: "",
      ville: "",
      popup: false,
      tokenPassword: "",
      tmplateAlertePassword: 0,
      tmpAlerteCode: 0,
      tmplateAlerteUserExiste: 0,
      statuts: [],
      statut: "",
      visible: true,
      specialiteAll: "",
      specialitesALl: [],
      PrecisionCentre: "",
      valide: false,
      info: 0,
      Token: "",
      codeServer: "",
      codeUser: "",
      isCodeSent: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePrenom = this.handleChangePrenom.bind(this);
    this.handleChangeNom = this.handleChangeNom.bind(this);
    this.handleChangeEmailP = this.handleChangeEmailP.bind(this);
    this.handleChangeEmailS = this.handleChangeEmailS.bind(this);
    this.handleChangeNum_telephone = this.handleChangeNum_telephone.bind(this);
    this.handleChangeRPPS = this.handleChangeRPPS.bind(this);
    this.handleChangeRGPD = this.handleChangeRGPD.bind(this);
    this.handleChangeAlerte = this.handleChangeAlerte.bind(this);
    this.handleChangeAlerteEmail = this.handleChangeAlerteEmail.bind(this);
    this.handleChangeSupprimer = this.handleChangeSupprimer.bind(this);
    this.handleChangeLaboratoire = this.handleChangeLaboratoire.bind(this);
    this.handleChangeVille = this.handleChangeVille.bind(this);
    this.handleChangeCodeUser = this.handleChangeCodeUser.bind(this);
    this.handleCode = this.handleCode.bind(this);
    this.CreateUserPayload = this.CreateUserPayload.bind(this);
  }

  componentWillMount() { }

  ////////////////////////////////
  // INITIALISATION DES DONNÉES //
  ////////////////////////////////

  componentDidMount() {
    setTimeout(() => {
      if (this.props.token && this.props.token.startsWith("psw=")) {
        this.setState({ tokenPassword: this.props.token.substr(4) });
      }

      if (this.props.token) {
        axios
          .post(`${URL[ENV]}users/fetchUserByEmail`, {
            email: this.props.token,
          })
          .then((res) => {
            this.setState({
              Token: res.data.token,
              prenom: res.data.data.prenom,
              nom: res.data.data.nom,
              emailP: res.data.data.email,
              emailS: res.data.data.email_sec ?? "",
              currentObjectSpecialite: {
                value: res.data.data.specialite,
                label: res.data.data.specialite,
              },
              currentObjectCenter: {
                value: res.data.data.centre,
                label: res.data.data.centre,
              },
              statut: res.data.data.status ?? "",
              specialite: res.data.data.specialite ?? "",
              centre: res.data.data.centre ?? "",
              Laboratoire: res.data.data.laboratoire ?? "",
              valide: res.data.data.valide,
            });
          })
          .catch((err) => {
            //alert("Token invalide");
            console.log(err);
          });
      }

      setTimeout(() => {
        if (this.props.token && this.state.emailP !== "") {
          axios
            .post(`${URL[ENV]}auth/rapidRegister`, {
              email: this.state.emailP,
            })
            .then((res) => {
              if (res.data.msg == "success") {
                localStorage.setItem("Token", this.state.Token);
                localStorage.setItem("email", this.state.emailP);
              }
            });
        }
        const hasRefreshed = localStorage.getItem("hasRefreshed");

        if (!hasRefreshed) {
          if (this.props.token && this.state.valide) {
            localStorage.setItem("Token", this.state.Token);
            localStorage.setItem("email", this.state.emailP);

            // Marquez que la page a été rafraîchie
            localStorage.setItem("hasRefreshed", "true");

            // Rafraîchissez la page une seule fois
            window.location.reload(false);
          }
        } else {
          localStorage.removeItem("hasRefreshed");
        }
      }, 400);


      //Récupération des centres
      axios
        .get(`${URL[ENV]}users/fetchCenter`, {})
        .then((res) => {
          const options = res.data.data.map((data, index) => {
            return {
              label: data.nom,
              value: data.nom,
              key: index,
            };
          });
          this.setState({
            centers: options,
          });

          this.state.centers.push(
            {
              label: "Autre",
              value: "Autre",
              key: 150,
            }
          );
        })

        .catch((err) => {
          console.log(err);
        });


      //Récupération des spécialitées
      axios
        .get(`${URL[ENV]}users/fetchSpe`, {})
        .then((res) => {
          const options2 = res.data.data.map((data, index) => {
            return {
              label: data.spe,
              value: data.spe,
              key: index,
            };
          });
          this.setState({
            specialites: options2,
          });

          this.state.specialites.push(
            {
              label: "Autre",
              value: "Autre",
              key: 74,
            }
          );
        })

        .catch((err) => {
          console.log(err);
        });

      const departements = arryDep.map((data, index) => {
        return {
          label: data.nom_departement,
          value: data.nom_departement,
          key: index,
        };
      });
      this.setState({
        departements: departements,
      });

      const countries = arryCountries.map((data, index) => {
        return {
          label: data.name_fr,
          value: data.name_fr,
          key: index,
        };
      });
      this.setState({
        countries: countries,
      });

      const statuts = arryStatuts.map((data, index) => {
        return {
          label: data.name,
          value: data.name,
          key: index,
        };
      });
      this.setState({
        statuts: statuts,
      });
    }, 200);
  }

  /////////////////////
  // EVENTS HANDLERS //
  /////////////////////

  // Formulaire
  handleChangePrenom(event) {
    this.setState({ prenom: event.target.value });
  }

  handleChangeNom(event) {
    this.setState({ nom: event.target.value });
  }

  handleChangeEmailP(event) {
    this.setState({ emailP: event.target.value });
  }

  handleChangeEmailS(event) {
    this.setState({ emailS: event.target.value });
  }

  handleChangeNum_telephone(event) {
    this.setState({ num_telephone: event.target.value });
  }

  handleChangeStatut = (statut) => {
    this.setState({ statut });
  };

  handleChangeCentre = (currentObjectCenter) => {
    this.setState({ currentObjectCenter });
  };

  handleChangeSpecialite = (currentObjectSpecialite) => {
    this.setState({ currentObjectSpecialite });
  };


  handleChangeLaboratoire(event) {
    this.setState({ Laboratoire: event.target.value });
  }

  handleChangePays = (pays) => {
    this.setState({ pays });
  };

  handleChangeDepartements = (departement) => {
    this.setState({ departement });
  };

  handleChangeVille(event) {
    this.setState({ ville: event.target.value });
  }

  handleChangeRPPS(event) {
    this.setState({ rpps: event.target.value });
  }

  handleChangeCodeUser(event) {
    this.setState({ codeUser: event.target.value })
  }

  // Alerte
  handleChangeAlerte(event) {
    this.setState({ tmpAlerte: 1 });

    setTimeout(() => {
      this.setState({
        tmpAlerte: 0,
      });
    }, "3000");
  }

  handleChangeAlerteCode(event) {
    this.setState({ tmpAlerteCode: 1 });

    setTimeout(() => {
      this.setState({
        tmpAlerte: 0,
      });
    }, "3000");
  }

  handleChangeAlerteEmail(event) {
    this.setState({ tmpAlerteEmail: 1 });

    setTimeout(() => {
      this.setState({
        tmpAlerteEmail: 0,
      });
    }, "3000");
  }

  handleChangeAlertePassword(event) {
    this.setState({ tmplateAlertePassword: 1 });

    setTimeout(() => {
      this.setState({
        tmplateAlertePassword: 0,
      });
    }, "5000");
  }

  handleChangeAlerteUserExiste(event) {
    this.setState({ tmplateAlerteUserExiste: 1 });

    setTimeout(() => {
      this.setState({
        tmplateAlerteUserExiste: 0,
      });
    }, "5000");
  }

  // Divers
  handleChangeSupprimer(event) {
    axios
      .post(`${URL[ENV]}users/deleteUserNotValidated`, {
        email: this.state.emailP,
      })
      .then((res) => {
        alert("Vos informations ont bien été supprimer");
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChangeRGPD(event) {
    this.setState({ rgpd: !this.state.rgpd });
  }

  handleAfficherInfo = (e) => {
    this.setState({ info: 1 });
  };

  handleCacherInfo = (e) => {
    this.setState({ info: 0 });
  };

  // envoie des données du formulaire
  handleSubmit() {
    var payload = this.CreateUserPayload();

    console.log('user infos', payload)

    if (!this.validateEmail(this.state.emailP)) {
      this.handleChangeAlerteEmail();
      return;
    }

    this.SendCode(payload);
  }

  handleCode() {
    var payload = this.CreateUserPayload();

    var isCodeValid = this.VerifyCode(this.state.codeUser);

    if (isCodeValid) {
      this.Register(payload);
    } else {
      this.handleChangeAlerteCode()
    }
  }

  ///////////////
  // FUNCTIONS //
  ///////////////

  CreateUserPayload() {
    var payload;

    if (
      this.state.prenom === "" ||
      this.state.nom === "" ||
      this.state.emailP === "" ||
      this.state.num_telephone === "" ||
      this.state.statut === ""
    ) {
      this.handleChangeAlerte();
      return;
    }

    // Alerte dépendante du statut
    switch (this.state.statut.value) {
      case "Médecin en exercice libéral":
        if (
          !this.state.currentObjectSpecialite ||
          this.state.rpps === "" ||
          this.state.currentObjectSpecialite.value === "" ||
          this.state.ville === ""
        ) {
          this.handleChangeAlerte();
          return;
        } else {
          payload = {
            prenom: this.state.prenom,
            nom: this.state.nom,
            email: this.state.emailP,
            email_sec: this.state.emailS,
            num_tel: this.state.num_telephone,
            status: this.state.statut.value,

            specialite: this.state.currentObjectSpecialite.value,
            rpps: this.state.rpps,
            ville: this.state.ville,
          }
        }
        break;

      case "Médecin étranger":
        if (
          !this.state.pays ||
          !this.state.currentObjectSpecialite ||
          this.state.currentObjectSpecialite.value === "" ||
          this.state.pays.value === "" ||
          this.state.ville === ""
        ) {
          this.handleChangeAlerte();
          return;
        } else {
          payload = {
            prenom: this.state.prenom,
            nom: this.state.nom,
            email: this.state.emailP,
            email_sec: this.state.emailS,
            num_tel: this.state.num_telephone,
            status: this.state.statut.value,

            specialite: this.state.currentObjectSpecialite.value,
            pays: this.state.pays.value,
            ville: this.state.ville,
          }
        }
        break;

      case "Industrie - Institutionnel - Constructeur":
        if (
          this.state.Laboratoire === ""
        ) {
          this.handleChangeAlerte();
          return;
        } else {
          payload = {
            prenom: this.state.prenom,
            nom: this.state.nom,
            email: this.state.emailP,
            email_sec: this.state.emailS,
            num_tel: this.state.num_telephone,
            status: this.state.statut.value,

            laboratoire: this.state.Laboratoire,
          }
        }
        break;

      default:
        if (
          !this.state.currentObjectCenter ||
          !this.state.currentObjectSpecialite ||
          this.state.currentObjectCenter.value === "" ||
          this.state.currentObjectSpecialite.value === ""
        ) {
          this.handleChangeAlerte();
          return;
        } else {
          payload = {
            prenom: this.state.prenom,
            nom: this.state.nom,
            email: this.state.emailP,
            email_sec: this.state.emailS,
            num_tel: this.state.num_telephone,
            status: this.state.statut.value,

            centre: this.state.currentObjectCenter.value,
            specialite: this.state.currentObjectSpecialite.value,
          };
        }
        break;
    }
    return payload;
  }

  SendCode(data) {
    axios
      .post(`${URL[ENV]}auth/registerCodeEmail_SMS`, data)
      .then((res) => {
        if (res.status == 201) {
          this.setState({ codeServer: res.data.code })
          this.setState({ isCodeSent: true })
        }
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 400")
          alert("Cet email et/ou ce N° de tél. est déjà utilisé");
        console.log(err);
      });
  }

  VerifyCode(code) {
    if (code === this.state.codeServer) {
      return true;
    }
    return false;
  }

  Register(data) {
    axios
      .post(`${URL[ENV]}auth/register`, data)
      .then((res) => {
        if (res.status == 200) {
          this.handleChangeAlerteUserExiste();
        } else {
          console.log("data", res.data)
          this.setState({ Token: res.data.token })
          localStorage.setItem("Token", res.data.token)
          localStorage.setItem("email", this.state.emailP)
          window.location.reload(false);
        }
      })
      .catch((err) => {
        alert("Erreur, veuillez réessayer ");
        console.log(err);
      });
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateMDP(mdp) {
    const res =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return res.test(mdp);
  }

  /////////////////////////
  // DISPLAY / RENDERING //
  /////////////////////////

  render() {
    // console.log("STATUT", this.state.statut)
    const { departement } = this.state;
    const { pays } = this.state;

    var templateAlerte = "";
    var templatePays = "";
    var templateDepartement = "";
    var templateRGPD = "";
    var template_status_dependent = "";
    var templateDefault = ""
    var templateIndustrie = ""
    var templateEtranger = ""
    var templateLiberal = ""

    /////////////////////////////////////
    // ALERTE, NOTIFICATIONS, MESSAGES //
    /////////////////////////////////////

    //Alerte
    if (this.state.tmpAlerte == 1) {
      templateAlerte = (
        <div className="ContainerAlerte">
          Veuillez remplir les champs comportant une étoile
        </div>
      );
    }

    //Alerte Code False
    if (this.state.tmpAlerteCode == 1) {
      templateAlerte = (
        <div className="ContainerAlerte">
          Le code entré ne correspond pas à celui envoyé
        </div>
      );
    }


    //Alerte Email
    var templateAlerteEmail = "";
    if (this.state.tmpAlerteEmail == 1) {
      templateAlerte = (
        <div className="ContainerAlerte">
          Veuillez entrer une adresse mail valide
        </div>
      );
    }

    //Alerte account already exist 
    var templateAlerteUserExiste = "";
    if (this.state.tmplateAlerteUserExiste == 1) {
      templateAlerteUserExiste = (
        <div className="ContainerAlerte3">
          Cet email est déjà associé à un compte, veuillez vous <br />
          connecter ou réinitialiser votre mot de passe si vous l’avez oublié
        </div>
      );
    }

    // RGPD
    if (this.state.rgpd == true) {
      templateRGPD = (
        <div className="containerLayer">
          <div className="containerRGPD">
            <div className="poprgpd">
              En cliquant sur "Supprimer" vous confirmez exercer <br />
              votre droit RGPD et nous supprimerons toutes les <br />
              informations vous concernant.
            </div>

            <div className="btnValider" onClick={this.handleChangeSupprimer}>
              SUPPRIMER
            </div>
            <p
              style={{
                textDecoration: "underline",
                marginTop: 20,
                cursor: "pointer",
              }}
              onClick={this.handleChangeRGPD}
            >
              {" "}
              Non, je ne souhaite pas supprimer mes informations
            </p>
          </div>
        </div>
      );
    }

    ///////////////
    // TEMPLATES //
    ///////////////

    // Spécialité
    var templateSpecialite = (
      <div
        style={{
          width: "50%",
          paddingLeft: 10,
          display: "flex",
          marginTop: 25,
        }}
        className="insideStatutSpecialite"
      >
        <span className="SelectNameSpecialite"> Spécialité * : </span>

        <Select
          options={this.state.specialites}
          value={this.state.currentObjectSpecialite}
          onChange={this.handleChangeSpecialite}
          placeholder="Taper les première lettres"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "#3c7493",
              color: "white",
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: "#3c7493",
              color: "white",
              backgroundColor: "#3c7493",
            }),

            SingleValue: (baseStyles, state) => ({
              ...baseStyles,
              color: "white !important",
            }),
          }}
        />
      </div>
    );

    // Centres
    var templateCentreComplet = (
      <div
        style={{
          width: "50%",
          paddingLeft: 10,
          display: "flex",
          marginTop: 25,
        }}
        className="insideCentre"
      >
        <span className="SelectNameCentre">Centre * : </span>

        <Select
          options={this.state.centers}
          value={this.state.currentObjectCenter}
          onChange={this.handleChangeCentre}
          placeholder="Taper les première lettres"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "#3c7493",
              color: "white",
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: "#3c7493",
              color: "white",
              backgroundColor: "#3c7493",
            }),

            SingleValue: (baseStyles, state) => ({
              ...baseStyles,
              color: "white !important",
            }),
          }}
        />
      </div>
    );

    // Laboratoire
    var templateLaboratoire = (
      <div
        className="LaboratoireContainer"
      >
        <span
          className="LaboratoireTitle"

        >
          {" "}
          Laboratoire * :
        </span>
        <input
          type="text"
          name="Laboratoire"
          required
          value={this.state.Laboratoire}
          onChange={this.handleChangeLaboratoire}
          style={{
            color: "white",
            width: "65%",
            marginTop: "20px",
            height: "30px",
          }}
        />
      </div>
    )

    // Pays
    var templatePays = (
      <div
        style={{
          paddingLeft: 10,
          display: "flex",
          marginTop: 25,
        }}
        className="insideDepartement"
      >
        <span className="centreSpecialite"> Pays * : </span>

        <Select
          options={this.state.countries}
          value={pays}
          onChange={this.handleChangePays}
          placeholder="Taper les première lettres"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "#3c7493",
              color: "white",
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: "#3c7493",
              color: "white",
              backgroundColor: "#3c7493",
            }),

            SingleValue: (baseStyles, state) => ({
              ...baseStyles,
              color: "white !important",
            }),
          }}
        />
      </div>
    )

    // Ville
    var templateVille = (
      <div
        style={{
          width: "50%",
          paddingLeft: 20,
          paddingBottom: 10,
          display: "flex",
        }}
        className="CentreVille"
      >
        <span
          style={{
            marginTop: "30px",
          }}
        >
          Ville * :
        </span>
        <input
          type="text"
          name="ville"
          required
          value={this.state.ville}
          onChange={this.handleChangeVille}
          style={{
            color: "white",
            width: "60%",
            marginTop: "20px",
            height: "30px",
          }}
        />
      </div>
    )

    // RPPS
    var templateRPPS = (
      <div
        style={{
          width: "50%",
          paddingLeft: 10,
          paddingBottom: 10,
          display: "flex",
        }}
      >
        <span
          style={{
            marginTop: "30px",
          }}
        >
          {" "}
          RPPS * :
        </span>
        <input
          type="text"
          name="RPPS"
          required
          value={this.state.rpps}
          onChange={this.handleChangeRPPS}
          style={{
            color: "white",
            width: "65%",
            marginTop: "20px",
            height: "30px",
          }}
        />
      </div>
    )

    templateDefault = (
      <>
        {templateCentreComplet}
        {templateSpecialite}
      </>
    )

    templateIndustrie = (
      <>
        {templateLaboratoire}
      </>
    )

    templateEtranger = (
      <>
        <div>
          {templateSpecialite}
          <div
            style={{
              justifyContent: "space-between"
            }}
            className="LastAndFirstName2"
          >
            {templatePays}
            {templateVille}
          </div>
        </div>
      </>
    )

    templateLiberal = (
      <>
        <div>
          {templateSpecialite}
          <div
            style={{
              justifyContent: "space-between"
            }}
            className="LastAndFirstName2"
          >
            {templateRPPS}
            {templateVille}
          </div>
        </div>
      </>
    )

    // affichage dépendant du statut
    switch (this.state.statut.value) {
      case "Médecin en exercice libéral":
        template_status_dependent = (
          <div>
            {templateLiberal}
          </div>
        );
        break;

      case "Médecin étranger":
        template_status_dependent = (
          <div>
            {templateEtranger}
          </div>
        );
        break;

      case "Industrie - Institutionnel - Constructeur":
        template_status_dependent = (
          <div className="LastAndFirstName2">
            {templateIndustrie}
          </div>
        );
        break;

      default:
        template_status_dependent = (
          <div className="LastAndFirstName2">
            {templateDefault}
          </div>
        );
        break;
    }


    var templateRGPDExist = "";

    if (this.props.token) {
      templateRGPDExist = (
        <div className="RGPD">
          {" "}
          <input
            type="radio"
            name="fav_language"
            value={this.state.rgpd}
            onChange={this.handleChangeRGPD}
            checked={this.state.rgpd}
            style={{ marginTop: 15 }}
          />
          <label for="html" className="labelRGPD">
            J'exerce mon droit au RGPD, je n'accepte pas la création de mon
            compte et je demande expressément à être retiré de la mailing-list
            Whitecoats. Je suis conscient que je ne pourrai pas visionner les
            vidéos Whitecoats, mais je me réserve le droit de m'inscrire
            spontanément une autre fois.
          </label>
        </div>
      );
    }

    var templateForm = "";
    var templateCodeVerif = "";

    templateForm = (
      <div>
        <div className="LastAndFirstName">
          <div
            style={{
              width: "50%",
              paddingLeft: 10,
              display: "inline-block",
            }}
            className="PrenomMobile"
          >
            Prénom * :
            <input
              type="text"
              className="NomPrenom"
              name="prenom"
              required
              value={this.state.prenom}
              onChange={this.handleChangePrenom}
            />
          </div>

          <div
            style={{
              width: "50%",
              paddingLeft: 10,
              display: "inline-block",
            }}
            className="NomMobile"
          >
            Nom * :
            <input
              type="text"
              className="NomPrenom"
              name="Nom"
              required
              value={this.state.nom}
              onChange={this.handleChangeNom}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            display: "inline-block",
          }}
          className="EmailPMobile"
        >
          Email principal * :
          <input
            type="email"
            className="MailPrincipale"
            name="email"
            required
            value={this.state.emailP}
            onChange={this.handleChangeEmailP}
          />
        </div>
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            display: "inline-block",
          }}
          className="EmailSMobile"
        >
          Email secondaire (facultatif) :
          <input
            type="email"
            className="MailSecondaire"
            name="email"
            placeholder=""
            value={this.state.emailS}
            onChange={this.handleChangeEmailS}
          />
        </div>
        <div
          className="rowNumAndStatus"
        >
          <div
            style={{
              width: "50%",
              paddingLeft: 10,
              display: "inline-block",
            }}
            className="NomMobile"
          >
            <div>
              N° de téléphone * :
              <input
                type="text"
                className="NumeroTelephone"
                name="Nom"
                required
                value={this.state.num_telephone}
                onChange={this.handleChangeNum_telephone}
              />
            </div>
            <span style={{ fontSize: 11, fontStyle: "italic" }}>
              (Il ne
              sera utilisé que pour vous
              connecter à Whitecoats)
            </span>
          </div>
          <div
            style={{
              width: "50%",
              paddingLeft: 10,
              display: "flex",
              marginTop: 25,
            }}
            className="insideStatutSpecialite"
          >
            <span className="SelectNameCentre">Statut * : </span>

            <Select
              options={this.state.statuts}
              value={this.state.statut}
              onChange={this.handleChangeStatut}
              placeholder="Choisissez votre statut"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "#3c7493",
                  color: "white",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#3c7493",
                  color: "white",
                  backgroundColor: "#3c7493",
                }),

                SingleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "white !important",
                }),
              }}
            />
          </div>
        </div>
        {template_status_dependent}

        <div className="Consentement">
          En validant la création de mon compte, j'accepte de recevoir les
          newsletters et notifications <br />
          de Whitecoats, et, j'adhère à la{" "}
          <a
            href="https://thewhitecoats.com/Politique-de-confidentialite.html"
            target="_blank"
          >
            politique de confidentialité
          </a>
          {" "}
          de Whitecoats.
        </div>
        <div className="Champs">*Champs obligatoire</div>
        <div className="btnValider" onClick={this.handleSubmit}>
          {" "}
          JE VALIDE MON COMPTE
        </div>

        {templateRGPDExist}

        <div style={{ color: "transparent" }}> .</div>
        {templateAlerte}
        {templateRGPD}
        {templateAlerteUserExiste}
      </div>
    );

    templateCodeVerif = (
      <div>
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            paddingTop: 25,
            fontSize: 20,
            display: "inline-block",
          }}
        >
          Un code d'authentification vous a été envoyé par Email et/ou SMS.
        </div>
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            display: "inline-block",
            fontSize: 15
          }}
          className="EmailSMobile"
        >
          Entrer le code :

          <input
            type="text"
            className="MailSecondaire"
            style={{ letterSpacing: 25, fontSize: 30, marginLeft: 10, width: "40%" }}
            name="codeUSer"
            placeholder=""
            value={this.state.codeUser}
            onChange={this.handleChangeCodeUser}
          />
        </div>
        <div className="btnValider" style={{ marginTop: 25 }} onClick={this.handleCode}>
          {" "}
          VALIDER LE CODE
        </div>
        <div style={{ color: "transparent" }}> .</div>
        {templateAlerte}
      </div>
    );

    return this.state.isCodeSent ? (
      <div className="ContainerRegister">{templateCodeVerif}</div>
    ) : (
      <div className="ContainerRegister">{templateForm}</div>
    );

  }
}

export default Register;
