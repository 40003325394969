import React, { Component } from "react";
import axios from "axios";
import writeXlsxFile from "write-excel-file";
import { URL, ENV, KEY } from "../Config/config.js";

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: this.props.match.params.pwd,
      isAdmin: false,
      usersRawData: [],
      keysToRemove: ["_id", "valide", "newsletter", "optin", "logConnexion", "quizResponses", "__v"], // Default fields to remove
      filters: {
        email: "",
        num_telephone: "",
        nom: "",
        prenom: "",
        status: ""
      },
      selectedFileName: null,
      stats: [],
      videoRawData: [],
    };

    this.fileInputRef = React.createRef();

    this.champs = [
      "centre",
      "email",
      "email_sec",
      "inscriptionDate",
      "lastconnect",
      "logConnexion",
      "newsletter",
      "nom",
      "num_telephone",
      "optin",
      "prenom",
      "quizResponses",
      "specialite",
      "status",
      "valide",
      "__v",
      "_id"
    ];
  }

  componentDidMount() {
    this.fetchIsAdmin();
    this.fetchUsersRawData();
    this.fetchAllOutStats();
  }

  //fetchers
  fetchIsAdmin() {
    axios.post(`${URL[ENV]}auth/isAdmin`, { pwd: this.state.password })
      .then((res) => {
        if (res.status === 200 && res.data.isAdmin) {
          this.setState({ isAdmin: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchUsersRawData() {
    axios.get(`${URL[ENV]}outStats/getUsersStats`, { params: { key: KEY } })
      .then((res) => {
        this.setState({ usersRawData: res.data.users });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  fetchAllOutStats() {
    axios.get(`${URL[ENV]}outStats/getVideoStats`, { params: { key: KEY } })
      .then((res) => {
        this.setState({ videoRawData: res.data.users });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  //functions
  filterUserData(users) {
    const { keysToRemove, filters } = this.state;

    return users
      .map(user => {
        let filteredUser = {};

        for (let key in user) {
          if (!keysToRemove.includes(key)) {
            filteredUser[key] = user[key];
          }
        }
        return filteredUser;
      })
      .filter(user => {
        for (let filterKey in filters) {
          const filterValue = filters[filterKey].toLowerCase();
          const userValue = user[filterKey] ? user[filterKey].toString().toLowerCase() : "";

          if (filterValue !== "" && !userValue.includes(filterValue)) {
            return false;
          }
        }
        return true;
      });
  }

  //handlers
  handleCheckboxChange = (champ) => {
    this.setState((prevState) => {
      const newKeysToRemove = [];
      for (let i = 0; i < prevState.keysToRemove.length; i++) {
        if (prevState.keysToRemove[i] !== champ) {
          newKeysToRemove.push(prevState.keysToRemove[i]);
        }
      }
      if (!prevState.keysToRemove.includes(champ)) {
        newKeysToRemove.push(champ);
      }
      return { keysToRemove: newKeysToRemove };
    });
  };

  handleFilterChange = (event) => {
    const name = event.target.name;
    const value = event.target.value.toLowerCase();

    this.setState((prevState) => {
      var updatedFilters = {};
      for (let key in prevState.filters) {
        updatedFilters[key] = prevState.filters[key];
      }
      updatedFilters[name] = value;
      return { filters: updatedFilters };
    });
  };

  handle_export_database = async () => {
    const statsArray = [this.state.usersRawData, this.state.videoRawData];

    this.setState({ stats: statsArray }, async () => {
      if (this.state.stats.length < 2) {
        alert("Stats data is missing!");
        return;
      }

      const today = new Date().toISOString().split("T")[0];
      const userFileName = `whitecoats_user_export_${today}.csv`;
      const videoFileName = `whitecoats_video_export_${today}.csv`;

      const userStats = this.state.stats[0] || [];
      const videoStats = this.state.stats[1] || [];

      // Ensure data is properly formatted
      if (!Array.isArray(userStats) || !Array.isArray(videoStats)) {
        alert("Invalid data format for export.");
        return;
      }

      if (userStats.length === 0 && videoStats.length === 0) {
        alert("No data to export.");
        return;
      }

      // Step 1: Convert User Data to CSV Format

      // Determine the maximum number of browsers and versions
      let maxBrowsers = 0;
      let maxVersions = 0;

      userStats.forEach(user => {
        if (user.browsers && Array.isArray(user.browsers)) {
          maxBrowsers = Math.max(maxBrowsers, user.browsers.length);
          user.browsers.forEach(browser => {
            maxVersions = Math.max(maxVersions, browser.versions.length);
          });
        }
      });

      // Construct headers dynamically
      const userHeaders = [
        "_id", "Nom", "Prénom", "Email", "Email Secondaire", "Statut", "Spécialité", "Centre", "Laboratoire", "Téléphone", "Date d'inscription", "Dernière connexion", "Logs connexion", "Newsletter", "Optin", "valide"
      ];
      for (let i = 1; i <= maxBrowsers; i++) {
        userHeaders.push(`Navigateur_${i}`);
        for (let j = 1; j <= maxVersions; j++) {
          userHeaders.push(`Navigateur-${i}_Version-${j}`);
          userHeaders.push(`Navigateur-${i}_Version-${j}_Count`);
        }
      }

      userStats.map(user => {
        if(user.logConnexion) user.logConnexion = user.logConnexion.replace(",", " -> ");

        if(user.newsletter) user.newsletter = "true";
        if(!user.newsletter) user.newsletter = "false";

        if(user.optin) user.optin = "true";
        if(!user.optin) user.optin = "false";
        
        if(user.valide) user.valide = "true";
        if(!user.valide) user.valide = "false";
      })

      // Create user rows
      const userRows = userStats.map(user => {
        let row = [
          user._id || "N/A",
          user.nom || "N/A",
          user.prenom || "N/A",
          user.email || "N/A",
          user.email_sec || "N/A",
          user.status || "N/A",
          user.specialite || "N/A",
          user.centre || "N/A",
          user.laboratoire || "N/A",
          user.num_telephone || "N/A",
          user.inscriptionDate || "N/A",
          user.lastconnect || "N/A",
          user.logConnexion || "N/A",
          user.newsletter || "N/A",
          user.optin || "N/A",
          user.valide || "N/A",
        ];

        // Add browser data
        if (user.browsers && Array.isArray(user.browsers)) {
          user.browsers.forEach((browser, bIndex) => {
            if (bIndex < maxBrowsers) {
              row.push(browser.name || "N/A");
              browser.versions.forEach((version, vIndex) => {
                if (vIndex < maxVersions) {
                  row.push(version.version || "N/A");
                  row.push(version.count || "N/A");
                }
              });
              // Fill remaining version columns
              for (let v = browser.versions.length; v < maxVersions; v++) {
                row.push("N/A", "N/A");
              }
            }
          });
        }

        // Fill remaining browser columns
        for (let b = user.browsers ? user.browsers.length : 0; b < maxBrowsers; b++) {
          row.push("N/A");
          for (let v = 0; v < maxVersions; v++) {
            row.push("N/A", "N/A");
          }
        }

        return row;
      });

      const userCSV = [userHeaders, ...userRows].map(row => row.join(",")).join("\n");

      videoStats.map(video => {
        if(video.fullVisionnage) video.fullVisionnage = "true";
        if(!video.fullVisionnage) video.fullVisionnage = "false";
      })
      
      // Step 2: Convert Video Data to CSV Format
      const videoHeaders = ["_id", "Nom de la vidéo", "Temps de visionnage", "Nombre de vues", "Visionnage complet", "Date du visionnage complet", "Nom Utilisateur", "Prénom Utilisateur", "Status", "Specialité","Centre", "Laboratoire"];
      const videoRows = videoStats.map(video => [
        video._id || "N/A",
        video.videoName || "N/A",
        video.timestamp || 0,
        video.vue || 0,
        video.fullVisionnage || "N/A",
        video.fullVisionnageDate || "N/A",
        video.user?.nom || "N/A",
        video.user?.prenom || "N/A",
        video.user?.status || "N/A",
        video.user?.specialite || "N/A",
        video.user?.centre || "N/A",
        video.user?.laboratoire || "N/A"
      ]);
      const videoCSV = [videoHeaders, ...videoRows].map(row => row.join(",")).join("\n");

      try {
          // Step 3: Download User CSV File
          const userBlob = new Blob([userCSV], { type: "text/csv" });
          const userUrl = window.URL.createObjectURL(userBlob); // Explicitly using window.URL
          const userLink = document.createElement("a");
          userLink.href = userUrl;
          userLink.download = userFileName;
          document.body.appendChild(userLink);
          userLink.click();
          document.body.removeChild(userLink);
          window.URL.revokeObjectURL(userUrl); // Explicitly using window.URL

          // Step 4: Download Video CSV File
          const videoBlob = new Blob([videoCSV], { type: "text/csv" });
          const videoUrl = window.URL.createObjectURL(videoBlob); // Explicitly using window.URL
          const videoLink = document.createElement("a");
          videoLink.href = videoUrl;
          videoLink.download = videoFileName;
          document.body.appendChild(videoLink);
          videoLink.click();
          document.body.removeChild(videoLink);
          window.URL.revokeObjectURL(videoUrl); // Explicitly using window.URL

          alert(`Fichiers ${userFileName} et ${videoFileName} générés avec succès!`);
      } catch (error) {
          console.error("Erreur lors de l'exportation:", error);
          alert("Erreur lors de la génération des fichiers CSV.");
      }
    });
  };

  handleFileChange = () => {
    const file = this.fileInputRef.current.files[0];
    if (file) {
      this.setState({ selectedFileName: file.name });
    } else {
      this.setState({ selectedFileName: null });
    }
  };

  handle_import_csv = () => {
    if (this.fileInputRef.current.files.length === 0) {
      alert("Veuillez sélectionner un fichier CSV.");
      return;
    }

    const file = this.fileInputRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);

    fetch(`${URL[ENV]}users/importUsers`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data.msg);
      })
      .catch((error) => {
        console.error("Error uploading CSV:", error);
        alert("Une erreur est survenue lors de l'importation.");
      });
  };

  render() {
    console.log(this.state.stats)

    console.log(this.state.stats)
    const filteredUsers = this.filterUserData(this.state.usersRawData);

    return (
      <>
        {this.state.isAdmin ? (
          <div style={{ display: 'flex', flexDirection: 'column', color: 'white', paddingTop: 50 }}>
            <h1 style={{ alignSelf: 'center' }}>Panneau d'administration</h1>

            <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 25 }}>
              <button style={{ padding: 15, borderRadius: 25, border: 'none', height: 50 }} onClick={this.handle_export_database}>Exporter la Base de Données</button>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ padding: 15, borderRadius: 25, border: "none", cursor: "pointer", background: "#fff", color: "#000" }}>
                  Télécharger un CSV
                  <input
                    type="file"
                    ref={this.fileInputRef}
                    accept=".csv"
                    onChange={this.handleFileChange}
                    style={{ display: "none" }}
                  />
                </label>

                <span style={{ marginLeft: 10, color: "#ddd" }}>
                  {this.state.selectedFileName ? `Fichier sélectionné: ${this.state.selectedFileName}` : "Aucun fichier sélectionné"}
                </span>

                <button onClick={this.handle_import_csv} style={{ padding: 15, borderRadius: 25, border: "none", marginLeft: 10 }}>
                  Ajouter les données du CSV dans la BDD
                </button>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-around' }}>

              {/* Checkbox Section */}
              <div style={{ paddingLeft: 25, display: 'flex', flexDirection: 'column', fontSize: 20 }}>
                <h3 style={{ textDecoration: 'underline' }}>Champs à retirer :</h3>
                {this.champs.map((champ) => (
                  <div key={champ}>
                    <input
                      type="checkbox"
                      checked={this.state.keysToRemove.includes(champ)}
                      onChange={() => this.handleCheckboxChange(champ)}
                    />
                    <label style={{ paddingLeft: 5 }} htmlFor={champ}>{champ}</label>
                  </div>
                ))}
              </div>

              {/* Filtering Form */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3 style={{ textDecoration: 'underline' }}>Filtrer par</h3>
                {["email", "num_telephone", "nom", "prenom", "status"].map((field) => (
                  <div key={field}>
                    <label style={{ fontSize: 25 }} htmlFor={field}>
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                    </label>
                    <input
                      style={{ marginLeft: 15, color: 'white', borderBottom: 'none', fontSize: 20 }}
                      type="text"
                      name={field}
                      value={this.state.filters[field]}
                      onChange={this.handleFilterChange}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* User List Table */}
            <div style={{ alignSelf: 'center' }}>
              <h2>Liste d'utilisateurs ({filteredUsers.length})</h2>
              <table border="1" cellPadding="8" style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    <th>Champs</th>
                    <th>Valeur</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map((user, index) => (
                      <React.Fragment key={index}>
                        {Object.keys(user).map((key) => (
                          <tr key={key}>
                            <td><strong>{key}</strong></td>
                            <td>{user[key] ? user[key].toString() : "N/A"}</td>
                          </tr>
                        ))}
                        {index !== filteredUsers.length - 1 && (
                          <tr>
                            <td colSpan="2" style={{ backgroundColor: "#ddd", height: "50px" }}>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" style={{ textAlign: "center" }}>Aucun utilisateur trouvé</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <h1 style={{ color: 'white' }}>This page is in development!</h1>
        )}
      </>
    );
  }
}

export default Admin;