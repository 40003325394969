const arryStatuts = [
  {
    name: "Médecin hospitalier",
  },
  {
    name: "Médecin en exercice libéral",
  },
  {
    name: "Médecin en exercice mixte",
  },
  {
    name: "Médecin étranger",
  },
  {
    name: "Interne",
  },
  {
    name: "Etudiant en faculté de médecine",
  },
  {
    name: "Industrie - Institutionnel - Constructeur",
  }
];

export default arryStatuts;