import React, { Component } from "react";
import "./Deconnection.css";

class Deconnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etatDecconnection: false,
      etatInfo: false,
    };

    this.handleDeconnection = this.handleDeconnection.bind(this);
    this.handleDeconnection2 = this.handleDeconnection2.bind(this);
    this.handleClearLocaStorage = this.handleClearLocaStorage.bind(this);
  }
  componentWillMount() {}
  componentDidMount() {
  }

  handleDeconnection(event) {
    this.setState({ etatDecconnection: true, etatInfo: true });
  }

  handleLangChangeInfo = () => {
    var page = 0;
    this.props.handleLangChangeInfoParent(page);
  };

  handleLangChangeInfo2 = () => {
    var page = 2;
    this.props.handleLangChangeInfoParent2(page);
  };

  handleDeconnection2(event) {
    setTimeout(() => {
      this.setState({ etatDecconnection: false, etatInfo: false });
    }, "5000");
  }

  handleClearLocaStorage(event) {
    localStorage.removeItem("email");
    localStorage.removeItem("valide");
    localStorage.removeItem("Token");
    localStorage.removeItem("isConnected");
    window.location.replace('/');
  }
  render() {
    var templateDeconnection = "";

    if (this.state.etatDecconnection == true) {
      templateDeconnection = (
        <div
          className="sedeconnecter"
          onMouseLeave={this.handleDeconnection2}
          onClick={this.handleClearLocaStorage}
        >
          Se déconnecter
        </div>
      );
    }

    var templateInfos = "";

    if (this.state.etatInfo == true && this.props.name == 2) {
      templateInfos = "";
    }
    if (this.state.etatInfo == true && this.props.name != 2) {
      {
        templateInfos = (
          <div
            className="Infos"
            onMouseLeave={this.handleDeconnection2}
            onClick={this.handleLangChangeInfo2}
          >
            Mes informations
          </div>
        );
      }
    }

    return (
      <div className="ContainerDeconnection" onClick={this.handleDeconnection}>
        <div className="deconnection">
          <img src="https://thewhitecoats.com/IMG/dev/Mon-profil.png" />
        </div>
        {templateDeconnection}
        {templateInfos}
      </div>
    );
  }
}

export default Deconnection;
