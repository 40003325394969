import React, { Component } from "react";
import "./Comment.css";
import axios from "axios";
import { URL, ENV } from "../../Config/config";

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etat: false,
      comment: "",
    };

    this.handleComment = this.handleComment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillMount() { }
  componentDidMount() { }

  handleComment(event) {
    this.setState({ etat: !this.state.etat });
  }

  handleChange(event) {
    this.setState({ comment: event.target.value });
  }

  handleSubmit(event) {
    if (this.state.comment != "") {
      var Token = localStorage.getItem("Token");

      axios
        .post(`${URL[ENV]}stats/feedback`, {
          token: Token,
          feedback: this.state.comment,
        })
        .then((res) => {
          this.setState({
            comment: "",
            etat: false,
          });
          // console.log(res.data);
        })
        .catch((err) => {
          //alert("Token invalide");
          // console.log(err);
        });
    } else {
      console.log("erreur");
    }
  }

  render() {
    var templateComment = "";

    if (this.state.etat == true) {
      templateComment = (
        <div className="ContainerComment" >
          <img
            style={{ zIndex: 1 }}
            src="https://thewhitecoats.com/IMG/dev/Feedback.png"
            className="imgFeedback2"
            onClick={this.handleComment}
          />
          <div className="containerChat">
            <div className="containerHeaderFeedback">
              <div className="TitleQuestion">Envoyer un commentaire : </div>
              <div className="CloseFeedback" onClick={this.handleComment}>
                X
              </div>
            </div>

            <form>
              <label>
                <textarea
                  value={this.state.comment}
                  onChange={this.handleChange}
                  placeholder=""
                />
              </label>
              <br></br>

              <div className="btnValiderComment" onClick={this.handleSubmit}>
                ENVOYER
              </div>
            </form>
          </div>
        </div>
      );
    }

    if (this.state.etat == false) {
      templateComment = (
        <div className="ContainerComment">
          <a>
            <img
              style={{ zIndex: 1 }}
              src="https://thewhitecoats.com/IMG/dev/Feedback.png"
              className="imgFeedback"
              onClick={this.handleComment}
            />
          </a>
        </div>
      );
    }

    return <div>{templateComment}</div>;
  }
}

export default Comment;
