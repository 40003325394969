const arryCountries = [
  {
    iso3: "AFG",
    name_fr: "Afghanistan",
  },
  {
    iso3: "ZAF",
    name_fr: "Afrique du Sud",
  },
  {
    iso3: "ALB",
    name_fr: "Albanie",
  },
  {
    iso3: "DZA",
    name_fr: "Algérie",
  },
  {
    iso3: "DEU",
    name_fr: "Allemagne",
  },
  {
    iso3: "AND",
    name_fr: "Andorre",
  },
  {
    iso3: "AGO",
    name_fr: "Angola",
  },
  {
    iso3: "ATG",
    name_fr: "Antigua-et-Barbuda",
  },
  {
    iso3: "SAU",
    name_fr: "Arabie Saoudite",
  },
  {
    iso3: "ARG",
    name_fr: "Argentine",
  },
  {
    iso3: "ARM",
    name_fr: "Arménie",
  },
  {
    iso3: "ABW",
    name_fr: "Aruba",
  },
  {
    iso3: "AUS",
    name_fr: "Australie",
  },
  {
    iso3: "AUT",
    name_fr: "Autriche",
  },
  {
    iso3: "AZE",
    name_fr: "Azerbaïdjan",
  },
  {
    iso3: "BHS",
    name_fr: "Bahamas",
  },
  {
    iso3: "BHR",
    name_fr: "Bahreïn",
  },
  {
    iso3: "BGD",
    name_fr: "Bangladesh",
  },
  {
    iso3: "BRB",
    name_fr: "Barbade",
  },
  {
    iso3: "BEL",
    name_fr: "Belgique",
  },
  {
    iso3: "BLZ",
    name_fr: "Belize",
  },
  {
    iso3: "BEN",
    name_fr: "Bénin",
  },
  {
    iso3: "BMU",
    name_fr: "Bermudes",
  },
  {
    iso3: "BTN",
    name_fr: "Bhoutan",
  },
  {
    iso3: "BLR",
    name_fr: "Biélorussie",
  },
  {
    iso3: "MMR",
    name_fr: "Birmanie",
  },
  {
    iso3: "BOL",
    name_fr: "Bolivie",
  },
  {
    iso3: "BIH",
    name_fr: "Bosnie-Herzégovine",
  },
  {
    iso3: "BWA",
    name_fr: "Botswana",
  },
  {
    iso3: "BRA",
    name_fr: "Brésil",
  },
  {
    iso3: "BRN",
    name_fr: "Brunei",
  },
  {
    iso3: "BGR",
    name_fr: "Bulgarie",
  },
  {
    iso3: "BFA",
    name_fr: "Burkina Faso",
  },
  {
    iso3: "BDI",
    name_fr: "Burundi",
  },
  {
    iso3: "KHM",
    name_fr: "Cambodge",
  },
  {
    iso3: "CMR",
    name_fr: "Cameroun",
  },
  {
    iso3: "CAN",
    name_fr: "Canada",
  },
  {
    iso3: "CPV",
    name_fr: "Cap Vert",
  },
  {
    iso3: "CHL",
    name_fr: "Chili",
  },
  {
    iso3: "CHN",
    name_fr: "Chine",
  },
  {
    iso3: "CYP",
    name_fr: "Chypre",
  },
  {
    iso3: "COL",
    name_fr: "Colombie",
  },
  {
    iso3: "COM",
    name_fr: "Comores",
  },
  {
    iso3: "COG",
    name_fr: "Congo",
  },
  {
    iso3: "COD",
    name_fr: "Congo (Rép. dém.)",
  },
  {
    iso3: "PRK",
    name_fr: "Corée du Nord",
  },
  {
    iso3: "KOR",
    name_fr: "Corée du Sud",
  },
  {
    iso3: "CRI",
    name_fr: "Costa Rica",
  },
  {
    iso3: "CIV",
    name_fr: "Côte dIvoire",
  },
  {
    iso3: "HRV",
    name_fr: "Croatie",
  },
  {
    iso3: "CUB",
    name_fr: "Cuba",
  },
  {
    iso3: "CUW",
    name_fr: "Curaçao",
  },
  {
    iso3: "DNK",
    name_fr: "Danemark",
  },
  {
    iso3: "DJI",
    name_fr: "Djibouti",
  },
  {
    iso3: "DMA",
    name_fr: "Dominique",
  },
  {
    iso3: "EGY",
    name_fr: "Egypte",
  },
  {
    iso3: "ARE",
    name_fr: "Emirats arabes unis",
  },
  {
    iso3: "ECU",
    name_fr: "Equateur",
  },
  {
    iso3: "ERI",
    name_fr: "Erythrée",
  },
  {
    iso3: "ESP",
    name_fr: "Espagne",
  },
  {
    iso3: "EST",
    name_fr: "Estonie",
  },
  {
    iso3: "SWZ",
    name_fr: "Eswatini",
  },
  {
    iso3: "USA",
    name_fr: "Etats-Unis",
  },
  {
    iso3: "ETH",
    name_fr: "Ethiopie",
  },
  {
    iso3: "FJI",
    name_fr: "Fidji",
  },
  {
    iso3: "FIN",
    name_fr: "Finlande",
  },
  {
    iso3: "FRA",
    name_fr: "France",
  },
  {
    iso3: "GAB",
    name_fr: "Gabon",
  },
  {
    iso3: "GMB",
    name_fr: "Gambie",
  },
  {
    iso3: "GEO",
    name_fr: "Géorgie",
  },
  {
    iso3: "GHA",
    name_fr: "Ghana",
  },
  {
    iso3: "GIB",
    name_fr: "Gibraltar",
  },
  {
    iso3: "GRC",
    name_fr: "Grèce",
  },
  {
    iso3: "GRD",
    name_fr: "Grenade",
  },
  {
    iso3: "GRL",
    name_fr: "Groenland",
  },
  {
    iso3: "GUM",
    name_fr: "Guam",
  },
  {
    iso3: "GTM",
    name_fr: "Guatemala",
  },
  {
    iso3: "GIN",
    name_fr: "Guinée",
  },
  {
    iso3: "GNB",
    name_fr: "Guinée-Bissau",
  },
  {
    iso3: "GNQ",
    name_fr: "Guinée-Équatoriale",
  },
  {
    iso3: "GUY",
    name_fr: "Guyana",
  },
  {
    iso3: "HTI",
    name_fr: "Haïti",
  },
  {
    iso3: "HND",
    name_fr: "Honduras",
  },
  {
    iso3: "HKG",
    name_fr: "Hong Kong (Chine)",
  },
  {
    iso3: "HUN",
    name_fr: "Hongrie",
  },
  {
    iso3: "IMN",
    name_fr: "Île de Man",
  },
  {
    iso3: "MUS",
    name_fr: "Ile Maurice",
  },
  {
    iso3: "CYM",
    name_fr: "Îles Caïmans",
  },
  {
    iso3: "FRO",
    name_fr: "Îles Féroé",
  },
  {
    iso3: "MNP",
    name_fr: "Îles Mariannes du Nord",
  },
  {
    iso3: "MHL",
    name_fr: "Îles Marshall",
  },
  {
    iso3: "SLB",
    name_fr: "Îles Salomon",
  },
  {
    iso3: "TCA",
    name_fr: "Îles Turques-et-Caïques",
  },
  {
    iso3: "VGB",
    name_fr: "Îles Vierges britanniques",
  },
  {
    iso3: "IND",
    name_fr: "Inde",
  },
  {
    iso3: "IDN",
    name_fr: "Indonésie",
  },
  {
    iso3: "IRQ",
    name_fr: "Irak",
  },
  {
    iso3: "IRN",
    name_fr: "Iran",
  },
  {
    iso3: "IRL",
    name_fr: "Irlande",
  },
  {
    iso3: "ISL",
    name_fr: "Islande",
  },
  {
    iso3: "ISR",
    name_fr: "Israël",
  },
  {
    iso3: "ITA",
    name_fr: "Italie",
  },
  {
    iso3: "JAM",
    name_fr: "Jamaïque",
  },
  {
    iso3: "JPN",
    name_fr: "Japon",
  },
  {
    iso3: "JOR",
    name_fr: "Jordanie",
  },
  {
    iso3: "KAZ",
    name_fr: "Kazakhstan",
  },
  {
    iso3: "KEN",
    name_fr: "Kenya",
  },
  {
    iso3: "KGZ",
    name_fr: "Kirghizistan",
  },
  {
    iso3: "KIR",
    name_fr: "Kiribati",
  },
  {
    iso3: "KOS",
    name_fr: "Kosovo",
  },
  {
    iso3: "KWT",
    name_fr: "Koweït",
  },
  {
    iso3: "LAO",
    name_fr: "Laos",
  },
  {
    iso3: "LSO",
    name_fr: "Lesotho",
  },
  {
    iso3: "LVA",
    name_fr: "Lettonie",
  },
  {
    iso3: "LBN",
    name_fr: "Liban",
  },
  {
    iso3: "LBR",
    name_fr: "Liberia",
  },
  {
    iso3: "LBY",
    name_fr: "Libye",
  },
  {
    iso3: "LIE",
    name_fr: "Liechtenstein",
  },
  {
    iso3: "LTU",
    name_fr: "Lituanie",
  },
  {
    iso3: "LUX",
    name_fr: "Luxembourg",
  },
  {
    iso3: "MAC",
    name_fr: "Macao (Chine)",
  },
  {
    iso3: "MKD",
    name_fr: "Macédoine du Nord",
  },
  {
    iso3: "MDG",
    name_fr: "Madagascar",
  },
  {
    iso3: "MYS",
    name_fr: "Malaisie",
  },
  {
    iso3: "MWI",
    name_fr: "Malawi",
  },
  {
    iso3: "MDV",
    name_fr: "Maldives",
  },
  {
    iso3: "MLI",
    name_fr: "Mali",
  },
  {
    iso3: "MLT",
    name_fr: "Malte",
  },
  {
    iso3: "MAR",
    name_fr: "Maroc",
  },
  {
    iso3: "MRT",
    name_fr: "Mauritanie",
  },
  {
    iso3: "MEX",
    name_fr: "Mexique",
  },
  {
    iso3: "FSM",
    name_fr: "Micronésie",
  },
  {
    iso3: "MDA",
    name_fr: "Moldavie",
  },
  {
    iso3: "MCO",
    name_fr: "Monaco",
  },
  {
    iso3: "MNG",
    name_fr: "Mongolie",
  },
  {
    iso3: "MNE",
    name_fr: "Monténégro",
  },
  {
    iso3: "MOZ",
    name_fr: "Mozambique",
  },
  {
    iso3: "NAM",
    name_fr: "Namibie",
  },
  {
    iso3: "NRU",
    name_fr: "Nauru",
  },
  {
    iso3: "NPL",
    name_fr: "Népal",
  },
  {
    iso3: "NIC",
    name_fr: "Nicaragua",
  },
  {
    iso3: "NER",
    name_fr: "Niger",
  },
  {
    iso3: "NGA",
    name_fr: "Nigéria",
  },
  {
    iso3: "NOR",
    name_fr: "Norvège",
  },
  {
    iso3: "NZL",
    name_fr: "Nouvelle-Zélande",
  },
  {
    iso3: "OMN",
    name_fr: "Oman",
  },
  {
    iso3: "UZB",
    name_fr: "Ouzbékistan",
  },
  {
    iso3: "PAK",
    name_fr: "Pakistan",
  },
  {
    iso3: "PLW",
    name_fr: "Palaos",
  },
  {
    iso3: "PAN",
    name_fr: "Panama",
  },
  {
    iso3: "PNG",
    name_fr: "Papouasie-Nouvelle-Guinée",
  },
  {
    iso3: "PRY",
    name_fr: "Paraguay",
  },
  {
    iso3: "NLD",
    name_fr: "Pays-Bas",
  },
  {
    iso3: "PER",
    name_fr: "Pérou",
  },
  {
    iso3: "PHL",
    name_fr: "Philippines",
  },
  {
    iso3: "POL",
    name_fr: "Pologne",
  },
  {
    iso3: "PRI",
    name_fr: "Porto Rico",
  },
  {
    iso3: "PRT",
    name_fr: "Portugal",
  },
  {
    iso3: "QAT",
    name_fr: "Qatar",
  },
  {
    iso3: "CA-QC",
    name_fr: "Québec",
  },
  {
    iso3: "CAF",
    name_fr: "République centrafricaine",
  },
  {
    iso3: "DOM",
    name_fr: "République dominicaine",
  },
  {
    iso3: "CZE",
    name_fr: "République tchèque",
  },
  {
    iso3: "ROU",
    name_fr: "Roumanie",
  },
  {
    iso3: "GBR",
    name_fr: "Royaume-Uni",
  },
  {
    iso3: "RUS",
    name_fr: "Russie",
  },
  {
    iso3: "RWA",
    name_fr: "Rwanda",
  },
  {
    iso3: "SXM",
    name_fr: "Saint Martin (partie néerlandaise)",
  },
  {
    iso3: "KNA",
    name_fr: "Saint-Christophe-et-Niévès",
  },
  {
    iso3: "SMR",
    name_fr: "Saint-Marin",
  },
  {
    iso3: "MAF",
    name_fr: "Saint-Martin (partie française)",
  },
  {
    iso3: "VCT",
    name_fr: "Saint-Vincent-et-les-Grenadines",
  },
  {
    iso3: "LCA",
    name_fr: "Sainte-Lucie",
  },
  {
    iso3: "SLV",
    name_fr: "Salvador",
  },
  {
    iso3: "WSM",
    name_fr: "Samoa",
  },
  {
    iso3: "ASM",
    name_fr: "Samoa américaines",
  },
  {
    iso3: "STP",
    name_fr: "Sao Tomé-et-Principe",
  },
  {
    iso3: "SEN",
    name_fr: "Sénégal",
  },
  {
    iso3: "SRB",
    name_fr: "Serbie",
  },
  {
    iso3: "SYC",
    name_fr: "Seychelles",
  },
  {
    iso3: "SLE",
    name_fr: "Sierra Leone",
  },
  {
    iso3: "SGP",
    name_fr: "Singapour",
  },
  {
    iso3: "SVK",
    name_fr: "Slovaquie",
  },
  {
    iso3: "SVN",
    name_fr: "Slovénie",
  },
  {
    iso3: "SOM",
    name_fr: "Somalie",
  },
  {
    iso3: "SDN",
    name_fr: "Soudan",
  },
  {
    iso3: "SSD",
    name_fr: "Soudan du Sud",
  },
  {
    iso3: "LKA",
    name_fr: "Sri Lanka",
  },
  {
    iso3: "SWE",
    name_fr: "Suède",
  },
  {
    iso3: "CHE",
    name_fr: "Suisse",
  },
  {
    iso3: "SUR",
    name_fr: "Suriname",
  },
  {
    iso3: "SYR",
    name_fr: "Syrie",
  },
  {
    iso3: "TJK",
    name_fr: "Tadjikistan",
  },
  {
    iso3: "TWN",
    name_fr: "Taïwan",
  },
  {
    iso3: "TZA",
    name_fr: "Tanzanie",
  },
  {
    iso3: "TCD",
    name_fr: "Tchad",
  },
  {
    iso3: "PSE",
    name_fr: "Territoires palestiniens",
  },
  {
    iso3: "THA",
    name_fr: "Thaïlande",
  },
  {
    iso3: "TLS",
    name_fr: "Timor oriental",
  },
  {
    iso3: "TGO",
    name_fr: "Togo",
  },
  {
    iso3: "TON",
    name_fr: "Tonga",
  },
  {
    iso3: "TTO",
    name_fr: "Trinité et Tobago",
  },
  {
    iso3: "TUN",
    name_fr: "Tunisie",
  },
  {
    iso3: "TKM",
    name_fr: "Turkménistan",
  },
  {
    iso3: "TUR",
    name_fr: "Turquie",
  },
  {
    iso3: "TUV",
    name_fr: "Tuvalu",
  },
  {
    iso3: "UGA",
    name_fr: "Uganda",
  },
  {
    iso3: "UKR",
    name_fr: "Ukraine",
  },
  {
    iso3: "URY",
    name_fr: "Uruguay",
  },
  {
    iso3: "VUT",
    name_fr: "Vanuatu",
  },
  {
    iso3: "VAT",
    name_fr: "Vatican",
  },
  {
    iso3: "VEN",
    name_fr: "Venezuela",
  },
  {
    iso3: "VNM",
    name_fr: "Viêt Nam",
  },
  {
    iso3: "YEM",
    name_fr: "Yémen",
  },
  {
    iso3: "ZMB",
    name_fr: "Zambie",
  },
  {
    iso3: "ZWE",
    name_fr: "Zimbabwe",
  },
];

export default arryCountries;