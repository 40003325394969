import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import "react-app-polyfill/ie11";
import white from "../Components/white";
import Admin from "../Components/admin";

class IndexRoute extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="IndexRoute">
        <Router>
          <div>
            <Route exact path="/" component={white} />
            <Route exact path="/:email" component={white} />
            <Route exact path="/admin/:pwd" component={Admin} />
          </div>
        </Router>
      </div>
    );
  }
}

export default IndexRoute;
