const arryDep = [
  {
    code_departement: "1",
    nom_departement: "Ain",
  },
  {
    code_departement: "2",
    nom_departement: "Aisne",
  },
  {
    code_departement: "3",
    nom_departement: "Allier",
  },
  {
    code_departement: "4",
    nom_departement: "Alpes-de-Haute-Provence",
  },
  {
    code_departement: "5",
    nom_departement: "Hautes-Alpes",
  },
  {
    code_departement: "6",
    nom_departement: "Alpes-Maritimes",
  },
  {
    code_departement: "7",
    nom_departement: "Ardèche",
  },
  {
    code_departement: "8",
    nom_departement: "Ardennes",
  },
  {
    code_departement: "9",
    nom_departement: "Ariège",
  },
  {
    code_departement: "10",
    nom_departement: "Aube",
  },
  {
    code_departement: "11",
    nom_departement: "Aude",
  },
  {
    code_departement: "12",
    nom_departement: "Aveyron",
  },
  {
    code_departement: "13",
    nom_departement: "Bouches-du-Rhône",
  },
  {
    code_departement: "14",
    nom_departement: "Calvados",
  },
  {
    code_departement: "15",
    nom_departement: "Cantal",
  },
  {
    code_departement: "16",
    nom_departement: "Charente",
  },
  {
    code_departement: "17",
    nom_departement: "Charente-Maritime",
  },
  {
    code_departement: "18",
    nom_departement: "Cher",
  },
  {
    code_departement: "19",
    nom_departement: "Corrèze",
  },
  {
    code_departement: "21",
    nom_departement: "Côte-d'Or",
  },
  {
    code_departement: "22",
    nom_departement: "Côtes-d'Armor",
  },
  {
    code_departement: "23",
    nom_departement: "Creuse",
  },
  {
    code_departement: "24",
    nom_departement: "Dordogne",
  },
  {
    code_departement: "25",
    nom_departement: "Doubs",
  },
  {
    code_departement: "26",
    nom_departement: "Drôme",
  },
  {
    code_departement: "27",
    nom_departement: "Eure",
  },
  {
    code_departement: "28",
    nom_departement: "Eure-et-Loir",
  },
  {
    code_departement: "29",
    nom_departement: "Finistère",
  },
  {
    code_departement: "2A",
    nom_departement: "Corse-du-Sud",
  },
  {
    code_departement: "2B",
    nom_departement: "Haute-Corse",
  },
  {
    code_departement: "30",
    nom_departement: "Gard",
  },
  {
    code_departement: "31",
    nom_departement: "Haute-Garonne",
  },
  {
    code_departement: "32",
    nom_departement: "Gers",
  },
  {
    code_departement: "33",
    nom_departement: "Gironde",
  },
  {
    code_departement: "34",
    nom_departement: "Hérault",
  },
  {
    code_departement: "35",
    nom_departement: "Ille-et-Vilaine",
  },
  {
    code_departement: "36",
    nom_departement: "Indre",
  },
  {
    code_departement: "37",
    nom_departement: "Indre-et-Loire",
  },
  {
    code_departement: "38",
    nom_departement: "Isère",
  },
  {
    code_departement: "39",
    nom_departement: "Jura",
  },
  {
    code_departement: "40",
    nom_departement: "Landes",
  },
  {
    code_departement: "41",
    nom_departement: "Loir-et-Cher",
  },
  {
    code_departement: "42",
    nom_departement: "Loire",
  },
  {
    code_departement: "43",
    nom_departement: "Haute-Loire",
  },
  {
    code_departement: "44",
    nom_departement: "Loire-Atlantique",
  },
  {
    code_departement: "45",
    nom_departement: "Loiret",
  },
  {
    code_departement: "46",
    nom_departement: "Lot",
  },
  {
    code_departement: "47",
    nom_departement: "Lot-et-Garonne",
  },
  {
    code_departement: "48",
    nom_departement: "Lozère",
  },
  {
    code_departement: "49",
    nom_departement: "Maine-et-Loire",
  },
  {
    code_departement: "50",
    nom_departement: "Manche",
  },
  {
    code_departement: "51",
    nom_departement: "Marne",
  },
  {
    code_departement: "52",
    nom_departement: "Haute-Marne",
  },
  {
    code_departement: "53",
    nom_departement: "Mayenne",
  },
  {
    code_departement: "54",
    nom_departement: "Meurthe-et-Moselle",
  },
  {
    code_departement: "55",
    nom_departement: "Meuse",
  },
  {
    code_departement: "56",
    nom_departement: "Morbihan",
  },
  {
    code_departement: "57",
    nom_departement: "Moselle",
  },
  {
    code_departement: "58",
    nom_departement: "Nièvre",
  },
  {
    code_departement: "59",
    nom_departement: "Nord",
  },
  {
    code_departement: "60",
    nom_departement: "Oise",
  },
  {
    code_departement: "61",
    nom_departement: "Orne",
  },
  {
    code_departement: "62",
    nom_departement: "Pas-de-Calais",
  },
  {
    code_departement: "63",
    nom_departement: "Puy-de-Dôme",
  },
  {
    code_departement: "64",
    nom_departement: "Pyrénées-Atlantiques",
  },
  {
    code_departement: "65",
    nom_departement: "Hautes-Pyrénées",
  },
  {
    code_departement: "66",
    nom_departement: "Pyrénées-Orientales",
  },
  {
    code_departement: "67",
    nom_departement: "Bas-Rhin",
  },
  {
    code_departement: "68",
    nom_departement: "Haut-Rhin",
  },
  {
    code_departement: "69",
    nom_departement: "Rhône",
  },
  {
    code_departement: "70",
    nom_departement: "Haute-Saône",
  },
  {
    code_departement: "71",
    nom_departement: "Saône-et-Loire",
  },
  {
    code_departement: "72",
    nom_departement: "Sarthe",
  },
  {
    code_departement: "73",
    nom_departement: "Savoie",
  },
  {
    code_departement: "74",
    nom_departement: "Haute-Savoie",
  },
  {
    code_departement: "75",
    nom_departement: "Paris",
  },
  {
    code_departement: "76",
    nom_departement: "Seine-Maritime",
  },
  {
    code_departement: "77",
    nom_departement: "Seine-et-Marne",
  },
  {
    code_departement: "78",
    nom_departement: "Yvelines",
  },
  {
    code_departement: "79",
    nom_departement: "Deux-Sèvres",
  },
  {
    code_departement: "80",
    nom_departement: "Somme",
  },
  {
    code_departement: "81",
    nom_departement: "Tarn",
  },
  {
    code_departement: "82",
    nom_departement: "Tarn-et-Garonne",
  },
  {
    code_departement: "83",
    nom_departement: "Var",
  },
  {
    code_departement: "84",
    nom_departement: "Vaucluse",
  },
  {
    code_departement: "85",
    nom_departement: "Vendée",
  },
  {
    code_departement: "86",
    nom_departement: "Vienne",
  },
  {
    code_departement: "87",
    nom_departement: "Haute-Vienne",
  },
  {
    code_departement: "88",
    nom_departement: "Vosges",
  },
  {
    code_departement: "89",
    nom_departement: "Yonne",
  },
  {
    code_departement: "90",
    nom_departement: "Territoire de Belfort",
  },
  {
    code_departement: "91",
    nom_departement: "Essonne",
  },
  {
    code_departement: "92",
    nom_departement: "Hauts-de-Seine",
  },
  {
    code_departement: "93",
    nom_departement: "Seine-Saint-Denis",
  },
  {
    code_departement: "94",
    nom_departement: "Val-de-Marne",
  },
  {
    code_departement: "95",
    nom_departement: "Val-d'Oise",
  },
  {
    code_departement: "971",
    nom_departement: "Guadeloupe",
  },
  {
    code_departement: "972",
    nom_departement: "Martinique",
  },
  {
    code_departement: "973",
    nom_departement: "Guyane",
  },
  {
    code_departement: "974",
    nom_departement: "La Réunion",
  },
  {
    code_departement: "976",
    nom_departement: "Mayotte",
  },
];

export default arryDep