export const Firebase_Config = {
  apiKey: "AIzaSyCxsxdatEx3irhQaikXqf9_M94nXrk7irA",
  authDomain: "statsapps-5b71c.firebaseapp.com",
  databaseURL: "https://statsapps-5b71c.firebaseio.com",
  projectId: "statsapps-5b71c",
  storageBucket: "",
  messagingSenderId: "78960443585",
  appId: "1:78960443585:web:2d8a472e435d558f",
};

export const URL = {
  dev: "http://localhost:8210/",
  prod: "https://serveur.thewhitecoats.com/",
};

export const ENV = "prod";

export const KEY = "testHenri8_WhiteCoats_2024";
