import React, { Component } from "react";
import "./Experts.css";
import Cookies from "./Cookies";

class Experts extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="ContainerExperts">
        <div className="ContainerTitre">
          <div className="TitreExperts"> NOS EXPERTS / COMITÉ DE RÉDACTION</div>

          <div className="Support">
            {" "}
            Un problème ou une question technique ? Contactez notre équipe
            support :{" "}
            <strong>
              {" "}
              <a href="mailto:support@thewhitecoats.com">
                support@thewhitecoats.com
              </a>{" "}
            </strong>
          </div>
        </div>

        <div className="ContainerCarre">
          <div>
            <div className="nomExperts">Dr. Aurélie SWALDUZ</div>
            <div className="centreExperts">
              {" "}
              Oncologue Thoracique
              <br />
              Centre Léon Bérard - Lyon
              <br /> <span style={{ color: "transparent" }}> .</span>
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/saison2/Aurelie.png"
              className="imagesExperts"
            />
          </div>

          <div>
            <div className="nomExperts">Dr. Mylène WESPIRER</div>
            <div className="centreExperts">
              {" "}
              Oncologue Thoracique
              <br />
              Centre Léon Bérard - Lyon
              <br /> <span style={{ color: "transparent" }}> .</span>
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/saison2/Mylene.png"
              className="imagesExperts"
            />
          </div>

          <div>
          <div className="nomExperts">Pr. Etienne GIROUX LEPRIEUR</div>
            <div className="centreExperts">
              {" "}
              Pneumologue Oncologue Thoracique <br />
              APHP Ambroise Paré
              <br /> <span style={{ color: "transparent" }}> .</span>
            </div>      
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/saison2/Giroux.png"
              className="imagesExperts"
            />
          </div>

          <div>
            <div className="nomExperts">Stéphanie MALARTRE-SAPIENZA</div>
            <div className="centreExperts">
              {" "}
              Infirmière en 
              Pratiques Avancées IPA
              <br /> Centre Léon Bérard – Lyon{" "}
              <br /> <span style={{ color: "transparent" }}> .</span>

            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/saison2/Stephanie.png"
              className="imagesExperts"
            />
          </div>

          <div>
            <div className="nomExperts">Pr. Vincent SIBAUD</div>
            <div className="centreExperts">
              {" "}
              Onco-dermatologue <br />
              IUCT Oncopole - Toulouse
              <br /> <span style={{ color: "transparent" }}> .</span>
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/saison2/Sibaud.png"
              className="imagesExperts"
            />
          </div>

          <div>
            <div className="nomExperts">Pr. Anne-Claire TOFFART</div>
            <div className="centreExperts">
              {" "}
              Pneumologue <br />
              Oncologie Thoracique <br />
              CHU Grenoble
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/saison2/Anne-Claire.png"
              className="imagesExperts"
            />
          </div>
          <div>
            <div className="nomExperts">Dr. Bertrand MENNECIER</div>
            <div className="centreExperts">
              {" "}
              Pneumologue <br /> Oncologie Thoracique <br />
              CHRU de Nancy
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/saison2/Bertrand.png"
              className="imagesExperts"
            />
          </div>
        </div>

        <Cookies />
      </div>
    );
  }
}

export default Experts;
