import React, { Component } from "react";
import "./Login.css";
import axios from "axios";
import { URL, ENV } from "../../Config/config";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailP: "",
      num_tel: "",
      codeServer: "",
      codeUser: "",
      isCodeSent: false,
      tmpAlerte: 0,
    };

    this.handleChangeEmailP = this.handleChangeEmailP.bind(this);

    this.handleChangeNum_tel = this.handleChangeNum_tel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCodeUser = this.handleChangeCodeUser.bind(this);
    this.handleChangeAlerte = this.handleChangeAlerte.bind(this);
    this.handleChangeAlerteEmail = this.handleChangeAlerteEmail.bind(this);
    this.handleCode = this.handleCode.bind(this);
  }
  componentWillMount() { }
  componentDidMount() {
    console.log(this.props.token);
  }

  handleChangeEmailP(event) {
    this.setState({ emailP: event.target.value });
  }

  handleChangeNum_tel(event) {
    this.setState({ num_tel: event.target.value });
  }

  handleChangeCodeUser(event) {
    this.setState({ codeUser: event.target.value })
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  handleChangeAlerte(event) {
    this.setState({ tmpAlerte: 1 });

    setTimeout(() => {
      this.setState({
        tmpAlerte: 0,
      });
    }, "3000");
  }

  handleChangeAlerteEmail(event) {
    this.setState({ tmpAlerteEmail: 1 });

    setTimeout(() => {
      this.setState({
        tmpAlerteEmail: 0,
      });
    }, "3000");
  }

  handleSubmit(event) {
    if ((this.validateEmail(this.state.emailP) && this.state.emailP !== "") || this.state.num_tel !== "") {
      var data = null;

      data = {
        email: this.state.emailP,
        num_tel: this.state.num_tel,
      };

      if (data) {
        this.SendCode(data)
      }
    } else {
      if (!this.state.emailP || !this.state.num_tel) {
        this.handleChangeAlerte();
      } else if (!this.validateEmail(this.state.emailP)) {
        this.handleChangeAlerteEmail();
      } else {
        this.handleChangeAlerte();
      }
    }
  }

  handleCode() {
    var data = null;

    data = {
      email: this.state.emailP,
      num_tel: this.state.num_tel,
    };

    var isCodeValid = this.VerifyCode(this.state.codeUser);

    if (isCodeValid) {
      this.Login(data);
    } else {
      this.handleChangeAlerteCode()
    }
  }

  SendCode(data) {
    if (data.num_tel && !data.email) {
      console.log("num tel", data)
      axios
        .post(`${URL[ENV]}auth/codeSMS`, data)
        .then((res) => {
          if (res.status == 201) {
            this.setState({ codeServer: res.data.code })
            this.setState({ isCodeSent: true })
          } else if (res.status == 204) {
            alert("Aucun compte n'est associer à ce numéro de téléphone, connectez-vous via votre e-mail.")
          }
        })
        .catch((err) => {
          alert("Erreur, veuillez réessayer ");
          console.log(err);
        });
    } else if (!data.num_tel && data.email) {
      axios
        .post(`${URL[ENV]}auth/codeEmail`, data)
        .then((res) => {
          if (res.status == 201) {
            this.setState({ codeServer: res.data.code })
            this.setState({ isCodeSent: true })
          } else if (res.status == 204) {
            alert("Aucun compte n'est associer à cet e-mail, veuillez d'abord vous créer un compte.")
          }
        })
        .catch((err) => {
          alert("Erreur, veuillez réessayer ");
          console.log(err);
        });
    } else {
      axios
        .post(`${URL[ENV]}auth/codeEmail_SMS`, data)
        .then((res) => {
          if (res.status == 201) {
            this.setState({ codeServer: res.data.code })
            this.setState({ isCodeSent: true })
          } else if (res.status == 204) {
            alert("Aucun compte n'est associer à cet e-mail ou à ce n° de téléphone, veuillez d'abord vous créer un compte.")
          }
        })
        .catch((err) => {
          alert("Erreur, veuillez réessayer ");
          console.log(err);
        });
    }
  }

  VerifyCode(code) {
    if (code === this.state.codeServer) {
      return true;
    }
    return false;
  }

  Login() {
    if ((this.validateEmail(this.state.emailP) && this.state.emailP !== "") || this.state.num_tel !== "") {
      var data = null;

      data = {
        email: this.state.emailP,
        num_tel: this.state.num_tel,
      };

      if (data) {
        axios
          .post(`${URL[ENV]}auth/login`, {
            email: this.state.emailP,
            num_tel: this.state.num_tel,
          })
          .then((res) => {
            localStorage.setItem("Token", res.data.token);
            localStorage.setItem("email", this.state.emailP);
            localStorage.setItem("isConnected", true)
            window.location.reload(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      if (!this.state.emailP || !this.state.num_tel) {
        this.handleChangeAlerte();
      } else if (!this.validateEmail(this.state.emailP)) {
        this.handleChangeAlerteEmail();
      } else {
        this.handleChangeAlerte();
      }
    }
  }

  render() {
    var templateAlerte = "";

    if (this.state.tmpAlerte == 1) {
      templateAlerte = (
        <div className="ContainerAlerte">Veillez remplir un des deux champs (email ou numéro de téléphone)</div>
      );
    }

    //Alerte Email

    var templateAlerteEmail = "";
    if (this.state.tmpAlerteEmail == 1) {
      templateAlerte = (
        <div className="ContainerAlerte">
          Veuillez entrer une adresse mail valide
        </div>
      );
    }
    var templateForm = (
      <div className="ContainerLogin">
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            display: "inline-block",
          }}
        >
          Email:
          <input
            type="text"
            className="inputLogin"
            name="email"
            required
            value={this.state.emailP}
            onChange={this.handleChangeEmailP}
          />
        </div>
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            paddingTop: 25,
            display: "flex",
          }}
        >
          <span style={{ paddingLeft: 5 }}>OU</span>
        </div>
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            paddingBottom: 15,
            display: "flex",
          }}
        >
          <span className="txtPasswordLogin">N° de téléphone:</span>

          <input
            type="text"
            className="inputPassword"
            name="num_tel"
            style={{ letterSpacing: 10 }}
            required
            value={this.state.num_tel}
            onChange={this.handleChangeNum_tel}
          />
        </div>
        <div className="btnValider" onClick={this.handleSubmit}>
          {" "}
          JE ME CONNECTE
        </div>
        <div style={{ color: "transparent" }}>.</div>
        {templateAlerte}
      </div>
    );

    var templateCodeVerif = (
      <div>
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            paddingTop: 25,
            fontSize: 20,
            display: "inline-block",
          }}
        >
          Un code d'authentification vous a été envoyé par Email et/ou SMS.
        </div>
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            display: "inline-block",
            fontSize: 15
          }}
          className="EmailSMobile"
        >
          Entrer le code :

          <input
            type="text"
            className="MailSecondaire"
            style={{ letterSpacing: 25, fontSize: 30, marginLeft: 10, width: "40%" }}
            name="codeUSer"
            placeholder=""
            value={this.state.codeUser}
            onChange={this.handleChangeCodeUser}
          />
        </div>
        <div className="btnValider" style={{ marginTop: 25 }} onClick={this.handleCode}>
          {" "}
          VALIDER LE CODE
        </div>
        <div style={{ color: "transparent" }}> .</div>
        {templateAlerte}
      </div>
    );

    return this.state.isCodeSent ? (
      <div className="ContainerRegister">{templateCodeVerif}</div>
    ) : (
      <div className="ContainerRegister">{templateForm}</div>
    );
  }
}

export default Login;
